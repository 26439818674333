import { twJoin } from '@pledge-earth/product-language';
import { Suspense, useCallback, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CommandPalette } from '../../components/CommandPalette/CommandPalette';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { LoadingGeneric } from '../../components/Loading/LoadingGeneric';
import { DesktopMenu } from '../../components/NavigationMenu/DesktopMenu';
import { MobileMenu } from '../../components/NavigationMenu/MobileMenu';
import { TestModeBar } from '../../components/TestModeBar/TestModeBar';
import { TopBar } from '../../components/TopBar/TopBar';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import { UsageNotice } from '../../components/UsageNotice/UsageNotice';

export function MainLayout(props: { testModeSensitive: boolean }) {
  const testModeEnabled = useIsTestMode();
  const showTestModeBar = testModeEnabled && props.testModeSensitive;

  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const menuExpandButtonRef = useRef<HTMLButtonElement>(null);
  const menuCollapseButtonRef = useRef<HTMLButtonElement>(null);

  const toggleMenuCollapsed = useCallback(() => {
    if (isMenuCollapsed) {
      setIsMenuCollapsed(false);
      setTimeout(() => menuCollapseButtonRef.current?.focus(), 0);
    } else {
      setIsMenuCollapsed(true);
      setTimeout(() => menuExpandButtonRef.current?.focus(), 0);
    }
  }, [isMenuCollapsed]);

  return (
    <>
      <FeatureFlag name="command-k">
        <CommandPalette />
      </FeatureFlag>

      <div
        id="MainLayout"
        className={twJoin(
          'group/layout grid min-h-full grid-rows-[min-content_1fr]',
          'lg:grid-cols-[15.75rem_1fr] lg:transition-[grid-template-columns] lg:data-menu-collapsed:grid-cols-[4.5rem_1fr]',
        )}
        data-menu-collapsed={isMenuCollapsed || undefined}
      >
        <header
          className={twJoin('sticky top-0 z-10 bg-background-subdued', 'lg:col-start-2 lg:bg-background-default')}
        >
          <TopBar onMenuExpanded={toggleMenuCollapsed} menuExpandButtonRef={menuExpandButtonRef}>
            <MobileMenu />
          </TopBar>

          {showTestModeBar ? <TestModeBar /> : null}
          {!testModeEnabled ? (
            <FeatureFlag name="usage-warnings">
              <UsageNotice />
            </FeatureFlag>
          ) : null}
        </header>

        <nav
          className={twJoin(
            'hidden',
            'lg:sticky lg:top-0 lg:row-span-2 lg:row-start-1 lg:block lg:h-dvh lg:overflow-y-scroll lg:border-r lg:border-default lg:bg-background-subdued lg:[scrollbar-width:none]',
          )}
        >
          <DesktopMenu onMenuCollapsed={toggleMenuCollapsed} menuCollapseButtonRef={menuCollapseButtonRef} />
        </nav>

        <main className="z-0 min-w-0 px-5 py-6 lg:p-8">
          <Suspense fallback={<LoadingGeneric delayMs={300} />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  );
}
