import {
  ActionMenu,
  CalculatorIcon,
  ChevronRightIcon,
  DashboardIcon,
  DeveloperToolIcon,
  DocumentApiIcon,
  EmissionIcon,
  ExternalIcon,
  FileArrowUpIcon,
  FlaskIcon,
  FullScreenIcon,
  GridConnectIcon,
  HomeIcon,
  LinkIcon,
  MenuItem,
  OffsetsIcon,
  OrganisationIcon,
  PuzzleIcon,
  ReceiptIcon,
  ReportIcon,
  Separator,
  twMerge,
} from '@pledge-earth/product-language';
import type { ReactNode } from 'react';
import type { Placement } from 'react-aria';
import { FormattedMessage } from 'react-intl';

import { ClientRoleEnum } from '../../services/graphql/generated';
import { ACL } from '../ACL/ACL';
import {
  Entitlement,
  hasAllOfEntitlements,
  hasEntitlement,
  PLATFORM_CLIENTS_ENTITLEMENT,
  PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT,
  PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT,
  PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT,
  PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT,
  PLATFORM_SUPPLIERS_ENTITLEMENT,
} from '../Entitlement/Entitlement';
import { FeatureFlag } from '../FeatureFlag/FeatureFlag';
import { allEnabled, FeatureFlags } from '../FeatureFlag/FeatureFlags';
import { NavItem, NavItemButton, NavItemLink, NavMenu, NavSection, NavSectionHeading } from '../Nav/Nav';

import { TestModeSwitch } from './TestModeSwitch/TestModeSwitch';

function FeatureFlagsAndEntitlements(props: {
  isEnabled: (flags: Set<string>) => boolean;
  isEntitled: (entitlements: Set<string>) => boolean;
  children: ReactNode;
  fallback?: ReactNode;
}) {
  return (
    <FeatureFlags isEnabled={props.isEnabled} fallback={props.fallback}>
      <Entitlement isEntitled={props.isEntitled} fallback={props.fallback}>
        {props.children}
      </Entitlement>
    </FeatureFlags>
  );
}

export function NavigationMenu({ className, subMenuPlacement }: { className?: string; subMenuPlacement?: Placement }) {
  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      {/* menu body top section */}
      <div className="flex flex-1 flex-col gap-3">
        <NavSection roles="*">
          <NavMenu>
            <NavItem roles="*">
              <NavItemLink to="/dashboard">
                <HomeIcon />
                <FormattedMessage id="leftMenu.home" />
              </NavItemLink>
            </NavItem>

            <FeatureFlag name="analytics-page">
              <NavItem roles="*">
                <ActionMenu
                  placement={subMenuPlacement}
                  triggerElement={
                    <NavItemButton to="/dashboards">
                      <DashboardIcon />
                      <FormattedMessage id="leftMenu.dashboards" />
                      <ChevronRightIcon className="ml-auto" />
                    </NavItemButton>
                  }
                >
                  <FeatureFlag name="analytics-emissions-overview">
                    <MenuItem href="/dashboards/emissions/overview">
                      <FormattedMessage id="dashboards.emissions.overview.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-overview-dbt">
                    <MenuItem href="/dashboards/emissions/overview-dbt">
                      <FormattedMessage id="dashboards.emissions.overview.dbt.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-shipments-north-america-road">
                    <MenuItem href="/dashboards/emissions/north-america-road-freight">
                      <FormattedMessage id="dashboards.emissions.north_america_road.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-client">
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_CLIENTS_ENTITLEMENT)}>
                      <MenuItem href="/dashboards/emissions/client">
                        <FormattedMessage id="dashboards.emissions.client.title" />
                      </MenuItem>
                    </Entitlement>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-supplier">
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_SUPPLIERS_ENTITLEMENT)}>
                      <MenuItem href="/dashboards/emissions/supplier">
                        <FormattedMessage id="dashboards.emissions.supplier.title" />
                      </MenuItem>
                    </Entitlement>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-intensity">
                    <MenuItem href="/dashboards/emissions/intensity">
                      <FormattedMessage id="dashboards.emissions.intensity.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-accuracy">
                    <MenuItem href="/dashboards/emissions/accuracy">
                      <FormattedMessage id="dashboards.emissions.accuracy.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-sea-freight">
                    <MenuItem href="/dashboards/emissions/sea-freight">
                      <FormattedMessage id="dashboards.emissions.sea_freight.title" />
                    </MenuItem>
                  </FeatureFlag>
                  <FeatureFlag name="analytics-emissions-air-freight">
                    <MenuItem href="/dashboards/emissions/air-freight">
                      <FormattedMessage id="dashboards.emissions.air_freight.title" />
                    </MenuItem>
                  </FeatureFlag>
                </ActionMenu>
              </NavItem>
            </FeatureFlag>
          </NavMenu>
        </NavSection>

        <NavSection roles="*">
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.title.measurement-and-analytics" />
          </NavSectionHeading>

          <NavMenu>
            <NavItem roles="*">
              <NavItemLink to="/emissions">
                <EmissionIcon />
                <FormattedMessage id="leftMenu.emissions" />
              </NavItemLink>
            </NavItem>

            <NavItem roles="*">
              <NavItemLink to="/calculators/freight">
                <CalculatorIcon />
                <FormattedMessage id="leftMenu.calculators" />
                <FullScreenIcon />
              </NavItemLink>
            </NavItem>

            <NavItem roles="*">
              <NavItemLink to="/reports/emissions">
                <ReportIcon />
                <FormattedMessage id="leftMenu.reports" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>

        <NavSection roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations]}>
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.title.offsetting-and-insetting" />
          </NavSectionHeading>
          <NavMenu>
            <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations]}>
              <NavItemLink to="/offsetting">
                <OffsetsIcon />
                <FormattedMessage id="leftMenu.marketplace" />
              </NavItemLink>
            </NavItem>
            <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations]}>
              <NavItemLink to="/orders">
                <ReceiptIcon />
                <FormattedMessage id="leftMenu.orders" />
              </NavItemLink>
            </NavItem>
            <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations]}>
              <NavItemLink to="/impact-links">
                <LinkIcon />
                <FormattedMessage id="leftMenu.impact-links" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>

        <NavSection roles="*">
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.title.data" />
          </NavSectionHeading>

          <NavMenu>
            <FeatureFlagsAndEntitlements
              isEnabled={allEnabled(['data-collection-outbound-page', 'data-collection-inbound-page'])}
              isEntitled={hasAllOfEntitlements([
                PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT,
                PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT,
              ])}
              fallback={
                // at most one of these will match
                <>
                  <FeatureFlag name="data-collection-outbound-page">
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_DATA_COLLECTION_OUTBOUND_ENTITLEMENT)}>
                      <NavItem roles="*">
                        <NavItemLink to="/data-collections/outbound">
                          <GridConnectIcon />
                          <FormattedMessage id="leftMenu.data-collection" />
                        </NavItemLink>
                      </NavItem>
                    </Entitlement>
                  </FeatureFlag>
                  <FeatureFlag name="data-collection-inbound-page">
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_DATA_COLLECTION_INBOUND_ENTITLEMENT)}>
                      <NavItem roles="*">
                        <NavItemLink to="/data-collections/inbound">
                          <GridConnectIcon />
                          <FormattedMessage id="leftMenu.data-collection" />
                        </NavItemLink>
                      </NavItem>
                    </Entitlement>
                  </FeatureFlag>
                </>
              }
            >
              <NavItem roles="*">
                <ActionMenu
                  placement={subMenuPlacement}
                  triggerElement={
                    <NavItemButton to="/data-collections">
                      <GridConnectIcon />
                      <FormattedMessage id="leftMenu.data-collection" />
                      <ChevronRightIcon className="ml-auto" />
                    </NavItemButton>
                  }
                >
                  <MenuItem href="/data-collections/outbound">
                    <FormattedMessage id="leftMenu.data-collection-outbound" />
                  </MenuItem>

                  <MenuItem href="/data-collections/inbound">
                    <FormattedMessage id="leftMenu.data-collection-inbound" />
                  </MenuItem>
                </ActionMenu>
              </NavItem>
            </FeatureFlagsAndEntitlements>

            <NavItem roles="*">
              <NavItemLink to="/imports">
                <FileArrowUpIcon />
                <FormattedMessage id="leftMenu.data-import" />
              </NavItemLink>
            </NavItem>

            <ACL roles={[ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Owner]}>
              <Entitlement
                isEntitled={hasAllOfEntitlements([
                  PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT,
                  PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT,
                ])}
                fallback={
                  // at most one of these will match
                  <>
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_DIRECTORY_SUPPLIER_ENTITLEMENT)}>
                      <NavItem roles={[ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Owner]}>
                        <NavItemLink to="/directory/supplier">
                          <OrganisationIcon />
                          <FormattedMessage id="leftMenu.directory" />
                        </NavItemLink>
                      </NavItem>
                    </Entitlement>
                    <Entitlement isEntitled={hasEntitlement(PLATFORM_DIRECTORY_CLIENT_ENTITLEMENT)}>
                      <NavItem roles={[ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Owner]}>
                        <NavItemLink to="/directory/client">
                          <OrganisationIcon />
                          <FormattedMessage id="leftMenu.directory" />
                        </NavItemLink>
                      </NavItem>
                    </Entitlement>
                  </>
                }
              >
                <ActionMenu
                  placement={subMenuPlacement}
                  triggerElement={
                    <NavItemButton to="/directory">
                      <OrganisationIcon />
                      <FormattedMessage id="leftMenu.directory" />
                      <ChevronRightIcon className="ml-auto" />
                    </NavItemButton>
                  }
                >
                  <MenuItem href="/directory/supplier">
                    <FormattedMessage id="leftMenu.directory-supplier" />
                  </MenuItem>
                  <MenuItem href="/directory/client">
                    <FormattedMessage id="leftMenu.directory-client" />
                  </MenuItem>
                </ActionMenu>
              </Entitlement>
            </ACL>

            <NavItem
              roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations, ClientRoleEnum.Developer]}
            >
              <NavItemLink to="/integrations">
                <PuzzleIcon />
                <FormattedMessage id="leftMenu.integrations" />
              </NavItemLink>
            </NavItem>

            <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Operations]}>
              <NavItemLink to="/test-data">
                <FlaskIcon />
                <FormattedMessage id="leftMenu.test-data" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>
      </div>

      <Separator className="-mx-4" />

      {/* menu body bottom section */}
      <NavSection roles="*">
        <NavMenu>
          <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Developer]}>
            <NavItemLink to="/developers">
              <DeveloperToolIcon />
              <FormattedMessage id="leftMenu.developers" />
            </NavItemLink>
          </NavItem>
          <NavItem roles={[ClientRoleEnum.Owner, ClientRoleEnum.Admin, ClientRoleEnum.Developer]}>
            <NavItemLink id="left-menu-api-docs" to="https://docs.pledge.io/docs/public-api" target="_blank noreferrer">
              <DocumentApiIcon />
              <FormattedMessage id="leftMenu.documentation" />
              <ExternalIcon />
            </NavItemLink>
          </NavItem>
        </NavMenu>

        <TestModeSwitch />
      </NavSection>
    </div>
  );
}
